import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'

import HeroChequeEspecial from '../cheque-especial-sucesso/sections/hero/_index'

import QRcodeModalExpirado from './assets/images/qrcode-modal-contratar-app.png'

import pageContext from './pageContext.json'

const ChequeEspecialContratarApp = () => {
  const urlMobileEspirado = 'https://bancointer.go.link/chequeEspecial?adj_t=1c43wnkh&adj_adgroup=contadigital&adj_%5B%E2%80%A6%5D%253Dsite_contadigital_onboarding_chequeespecial_contratarapp'
  const domReady = useDomReady()
  const [ isOpenModal, setIsOpenModal ] = useState(false)

  const openModalExpirado = domReady && (
    <Modal isModalOpen={isOpenModal} setIsModalOpen={setIsOpenModal} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpenModal}
        setIsModal={setIsOpenModal}
        title='Acesse o nosso Super App'
        subtitle="<span class='text-left'>Você será redirecionado para o Super App para utilizar o Cheque Especial.</span>"
        instructions="<span class='text-left'>Abra a câmera do seu celular e aponte para o QR Code acima. É rápido e fácil!</span>"
        qrCode={QRcodeModalExpirado}
      />
    </Modal>
  )

  return (
    <Layout pageContext={pageContext}>
      {openModalExpirado}
      <HeroChequeEspecial
        title='Faça a ativação do Cheque Especial pelo Super App'
        subTitle='Você possui duas contas no Inter, acesse a conta que você deseja contratar para ativar o Cheque Especial.'
        setIcon='alert'
        url={urlMobileEspirado}
        setIsOpenModal={setIsOpenModal}
      />
    </Layout>
  )
}

export default ChequeEspecialContratarApp
